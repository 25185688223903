.awards {
  position: relative;
  margin-top: 10px;
}
.awards .inner {
  left: 50%;
  margin-left: -500px;
  /* height: 180px; */
  height: auto;
}

.awards .inner.hide {
  visibility: hidden;
}

.awards .inner .history {
  left: auto;
  width: 100%;
  position: relative;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 30px 25px 20px 25px;
  background-color: #f6eeee;
  border-radius: 10px;
}

.awards .inner .history_title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  height: 40px;
}

.awards .inner .history_title a {
  display: flex;
}

.awards .inner .history_title h2 {
  font-size: 18px;
  font-weight: 700;
  color: #111821;
  margin-right: 10px;
  height: 100%;
  display: flex;
  align-items: center;
}
.awards .inner .history_title span {
  display: flex;
  align-items: center;
  height: 100%;
  color: #000;
  font-weight: 600;
  font-size: 22px;
}

.awards .inner .history_title span:hover,
.awards .inner .history_title h2:hover {
  color: var(--main-color);
  transition: color 0.5s;
  cursor: pointer;
}

.awards .inner .history ul li {
  background-color: aquamarine;
  padding: 10px;
  border-radius: 7px;
  display: block;
  border-width: 2px;
  margin-bottom: 8px;
  display: flex;
}
.awards .inner .history ul li:hover {
  transition: color 0.5s;
  background-color: yellow;
}
.awards .inner .history ul li span {
  display: block;
  font-weight: 700;
}
.awards .inner .history ul li span:first-child {
  width: 300px;
}

.awards .grade_pc,
.awards .grade_mo {
  position: relative;
  margin-top: 10px;
}
.awards .grade_mo {
  display: none;
}
.awards .grade_pc img {
  width: 100%;
}
.awards .inner.hide {
  height: 0;
  margin-top: 0;
}

@media (max-width: 1000px) {
  .awards .inner {
    display: block;
    left: auto;
    margin: 0 auto;
    width: 100%;
    height: auto;
    position: relative;
  }
  .awards .inner .history {
    left: auto;
    width: 100%;
    position: relative;
    margin: 0 auto;
    padding: 30px 20px 20px 20px;
    border-radius: 10px;
  }
}

@media (max-width: 740px) {
  .awards .inner {
    max-width: 100vw;
    overflow: hidden;
  }
  .awards .inner {
    display: block;
    left: auto;
    margin: 0 auto;
    width: 100%;
    height: auto;
    position: relative;
  }
  .awards .inner .history_title {
    height: 30px;
  }
  .awards .inner .history_title h2 {
    font-size: 15px;
  }
  .awards .inner .history_title span {
    font-size: 19px;
  }
  .awards .inner .history_content span:first-child {
    font-size: 15px;
    padding-bottom: 10px;
  }
  .awards .inner .history_content span:last-child {
    font-size: 13.5px;
    background-color: yellow;
    padding: 10px;
  }
  .awards .inner .history ul li{
    display: block;
    width: auto;
  }
}


@media (max-width: 740px) {
  .awards .grade_pc {
    display: none;
  }
  .awards .grade_mo {
    display: block;
  }
  .awards .grade_mo img {
    width: 100%;
  } 
}



