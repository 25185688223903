.notice {
  position: relative;
  margin-top: 10px;
}

.notice .inner {
  left: 50%;
  margin-left: -500px;
  /* height: 180px; */
  height: auto;
  display: flex;
}

.notice .inner .notice_title a {
  display: flex;
}

.notice .inner .notice_left {
  left: auto;
  width: 100%;
  position: relative;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 30px 25px 20px 25px;
  background-color: #f6eeee;
  border-radius: 10px;
}

.notice .inner .notice_title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  height: 40px;
}
.notice .inner .notice_title h2 {
  font-size: 18px;
  font-weight: 700;
  color: #111821;
  margin-right: 10px;
  height: 100%;
  display: flex;
  align-items: center;
}
.notice .inner .notice_title span {
  display: flex;
  align-items: center;
  height: 100%;
  color: #000;
  font-weight: 600;
  font-size: 22px;
}

.notice .inner .column a:hover,
.notice .inner .notice_title span:hover,
.notice .inner .notice_title h2:hover {
  color: var(--main-color);
  transition: color 0.5s;
  cursor: pointer;
}

.notice .inner .notice_middle {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  height: auto;
  z-index: 2;
}

.notice .inner .notice_middle .awardToggleBtn {
  width: 100%;
  height: 20%;
  position: relative;
  bottom: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.notice .inner .notice_middle .awardToggleBtn .material-symbols-outlined {
  font-size: 30px;
  display: block;
  transform: rotate(180deg);
  transition: all 0.5s;
}
.notice .inner .notice_middle .awardToggleBtn.rotate .material-symbols-outlined {
  transform: rotate(0deg);
}
.notice .inner .notice_middle .awardToggleBtn:hover .material-symbols-outlined {
  color: var(--main-color);
}
.notice .inner .notice_middle .awardToggleBtn:hover .tooltiptext.rotate,
.notice .inner .notice_middle .awardToggleBtn.rotate:hover .tooltiptext {
  visibility: visible;
  top: 10px;
  z-index: 2;
}

.notice .inner .column-list {
  height: 35px;
}

.notice .inner .column-list ul li {
  /* display: inline-block; */
  border-bottom: 1px solid #000;
}
.notice .inner .column-list  a {
  font-size: 15px;
  align-items: center;
  width: 100%;
  height: 30px;
  /* max-width: calc(100% - 20px); */
}

@media (max-width: 1000px) {
  .notice .inner {
    display: block;
    left: auto;
    margin: 0 auto;
    width: 100%;
    height: auto;
    position: relative;
  }
  .notice .inner .notice_left {
    left: auto;
    width: 100%;
    position: relative;
    margin: 0 auto;
    padding: 30px 25px 20px 25px;
    border-radius: 10px;
  }
  .notice .inner .notice_left {
    left: auto;
  }
}

@media (max-width: 740px) {
  .notice .inner .notice_title {
    height: 30px;
  }
  .notice .inner .notice_title h2 {
    font-size: 15px;
  }
  .notice .inner .notice_title span {
    font-size: 19px;
  }
  .notice .inner .notice_left {
    padding: 15px 15px;
  }
  .notice .inner .notice_left .column-list {
    font-size: 10px;
  }
}
