.banner {
  margin-top: 65px;
  background-position: center;
}
.banner .title_pc,
.banner .title_mo {
  position: relative;
}
.banner .title_mo {
  display: none;
}
.banner .title_pc img {
  width: 100%;
}

/* 신청하기 */
.banner .btn {
  position: absolute;
  bottom: 20px;
  right: 270px;
}

@media (max-width: 1000px) {
  .banner .inner {
    height: auto;
  }
  .banner .btn {
    position: absolute;
    bottom: 20px;
    right: 250px;
  }
}

@media (max-width: 740px) {
  .banner {
    margin-top: 55px;
  }
  .banner .title_pc {
    display: none;
  }
  .banner .title_mo {
    display: block;
  }
  .banner .title_mo img {
    width: 100%;
  }
  .banner .btn {
    position: absolute;
    bottom: 5px;
    left: 50px;
  }
}