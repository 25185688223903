/* footer upper */
footer {
  width: 100%;
  height: 100%;
  background-color: #f6f5f0;
  color: #333333;
  position: relative;
}
footer .footer-upper {
  position: relative;
  padding: 20px 0 17px;
  cursor: pointer;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  font-size: 15px;
  border-bottom: 1px solid var(--border-bottom-color);
}
footer .footer-upper > li a {
  display: block;
  padding: 4px 16px;
  color: #333;
}

/* footer lower */
footer .footer-lower {
  width: 100%;
  height: 100%;
  padding: 20px 0;
  position: relative;
}
footer .footer-lower ul {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  letter-spacing: 2;
  line-height: 2;
}
footer .footer-lower ul > li {
  position: relative;
}
footer .footer-lower ul > li::before {
  content: "";
  width: 1px;
  height: 12px;
  background-color: var(--border-bottom-color);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
footer .footer-lower ul > li > div {
  padding: 2px 16px;
}
footer .footer-lower ul > li > div > span {
  color: #000;
  font-weight:500;
}
footer .footer-lower ul > li:first-child::before {
  display: none;
}

@media (max-width: 740px) {
  .footer-upper {
    flex-wrap: wrap;
  }
}