/* badges */
header .badges {
  position: absolute;
  top: 77px;
  right: 12px;
}
header .badges .badge {
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 12px;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, .15);
  cursor: pointer;
}

/* to-top */
header #to-top {
  width: 42px;
  height: 42px;
  background-color: #fff;
  color: var(--main-color);
  font-weight: 700;
  border: 2.5px solid var(--main-color);
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 9;
  transform: translateX(100);
}
header #to-top:hover {
  color: #fff;
  background-color: var(--main-color);
  border: 2.5px solid #fff;
}
@media (max-width: 1000px) {
  header .badges .badge {
    display: none;
  }
}
@media (max-width: 740px) {
  header #to-top {
    width: 35px;
    height: 35px;
  }
}