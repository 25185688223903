header {
  width: 100%;
  background-color: var(--color-white);
  border-bottom: 1px solid var(--border-bottom-color);
  position: fixed;
  top: 0;
  z-index: 9;
  box-shadow: 0 10px 10px -10px rgba(0,0,0,0.2);
}
header .inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
header .inner .menu-starter {
  display: none;
}

/* 로고 */
header .logo {
  width: 200px;
  position: relative;
}
header .logo img {
  width: 270px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
}

/* menu, member common */
header .menu, header .member {
  display: flex;
  align-items: center;
}

/* menu */
header .menu .item > div {
  font-size: 16px;
  font-weight: 700;
  display: flex;
  padding: 22px 22px;
  transition: 0.4s;
}
header .menu .item > div:hover {
  background-color: var(--main-color);
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

/* member */
header .member .member_bar {
  width: 1.5px;
  height: 12px;
  background-color: #555555;
  margin: auto 12px;
}
header .member > ul {
  padding: 15px 8px;
  cursor: pointer;
}
.tooltip {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tooltip:hover .tooltiptext {
  visibility: visible;      /* hover 이벤트 발생시 영역을 보여줌 */
}
.tooltip .material-symbols-outlined {
  transition: color 0.5s;
}
.tooltip:hover .material-symbols-outlined {
  color: var(--main-color);
}

/* sub-menu */
header .menu .sub_menu {
  width: 100%;
  height: 65px;
  font-weight: 500;
  position: absolute;
  display: none;
  justify-content: center;
  align-items: center;
  top: 65px;
  left: 0;
  border-bottom: 1px solid var(--border-bottom-color);
  border-top: 1px solid var(--border-bottom-color);
  box-shadow: 0 10px 10px -10px rgba(0,0,0,0.2);
  font-size: 15px;
}
header .menu .sub_menu > li {
  padding: 22px 22px;
}
header .menu .sub_menu > li > a {
  color: #333;
}
header .menu .sub_menu > li:hover a {
  text-decoration: underline;
  color: var(--main-color);
  cursor: pointer;
}
header .menu .item:hover .sub_menu {
  background-color: #fff;
  display: flex;
}
header .clone-menu {
  display: none;
}

@media (max-width: 1140px) {
  .tooltip:last-child {
    display: flex;
    flex-direction: column;
    align-items: end;
  }
}

@media (max-width: 740px) {
  header::before {
    content: "";
    position: fixed;
    top: 50px;
    left: 0;
    width: 100%;
    height: 0;
    transition: .4s;
  }
  header .inner {
    height: 55px;
    padding: 0 10PX;
    box-sizing: border-box;
  }
  header .inner .logo {
    margin: 0 auto;
  }
  header .inner .logo img {
    max-width: 220px;
  }
  header .inner .menu,
  header .inner .member > ul:first-child, 
  header .inner .member > span {
    display: none;
  }
  header .inner > .menu-starter {
    cursor: pointer;
  }
  header .member > ul {
    padding: 0;
  }
  header .inner > .menu-starter,
  header .inner .logo {
    display: flex;
  }
  header .inner > .menu-starter span {
    display: block;
    width: 15px;
    height: 1.5px;
    background-color: var(--color-black);
    margin-bottom: 6px;
    transition: .4s;
  }
  header .inner > .menu-starter:hover span {
    background-color: var(--main-color);
  }
  header .inner > .menu-starter span:last-child {
    margin-bottom: 0;
  }
  header .clone-menu h3 {
    font-size: 12px;
    color: var(--color-font-darkgray);
    margin-top: 20px; 
    margin-left: -7px;
  }
  header .clone-menu {
    display: block;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    transition: .4s;
    transform: translate(0, -50px);
  }
  header .clone-menu ul {
    max-width: 590px;
    margin: 4px auto;
  }
  header .clone-menu ul li {
    border-bottom: 1px solid #d2d2d2;
  }
  header .clone-menu ul li:last-child {
    border-bottom: none;
  }
  header .clone-menu ul li a {
    padding: 16px 0;
    display: block;
    font-size: 14px;
    color: #000;
  }
  header .clone-menu ul li:hover a {
    color: var(--main-color);
    text-decoration: underline;
  }
  header.menuing {
    background-color: var(--color-white);
  }
  header.menuing::before {
    height: 100vh;
    background-color: var(--color-white);
  }
  header.menuing .inner .member {
    opacity: 0;
    visibility: hidden;
  }
  header.menuing .inner > .menu-starter span:first-child {
    transform: translate(0, 3.5px) rotate(45deg);
  }
  header.menuing .inner > .menu-starter span:last-child {
    transform: translate(0, -3.5px) rotate(-45deg);
  }
  header.menuing .clone-menu {
    visibility: visible;
    opacity: 1;
    transform: translate(0, 0);
  }
}
