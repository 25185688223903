.siteterm {
  margin-top: 65px;
  color: #333;
}
.siteterm .header {
  padding: 50px 50px 0;
  text-align: center;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.siteterm .header > img {
  width: 20px;
  display: inline-block;
}
.siteterm .header > h1 {
  font-size: 30px;
  padding: 3px 0;
}
.siteterm .header > p {
  padding: 8px 0;
  font-size: 18px;
}
/* 하단 내용 */
.siteterm .term {
  margin-bottom: 100px;
}
.siteterm .term .term-title {
  font-size: 17px;
  font-weight: 500;
  margin-top: 40px;
  margin-bottom: 10px;
}
.siteterm .term .term-text {
  padding: 0.15em 0;
  font-size: 15px;
  color: #777;
  line-height: 1.7em;
}