:root {
  --main-color: #00aced;
  --swiper-bg-color: #f6f5ef;
  --border-bottom-color: #ddd;
  --color-black: #000;
  --color-white: #fff;
  --color-font-darkgray: #6e6e6e;
}
html.fixed {
  position: fixed;
  overflow-y: scroll;
  width: 100%;
}
body {
  margin: 0;
  color: #333;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  font-family: 'Noto Sans KR', sans-serif;
}
img {
  display: block;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
a {
  text-decoration: none;
  color: inherit;
}
ul {
  list-style: none;
}
/* 툴팁 텍스트 */
.tooltiptext {
  visibility: hidden;       /* 이벤트가 없으면 툴팁 영역을 숨김 */
  background-color: #00aced;
  color: #fff;
  font-size: 13px;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  margin-top: 26px;
  z-index: 1;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}
/* 버튼 */
.btn {
  width: 130px;
  padding: 10px;
  border: 2px solid #00aced;
  border-radius: 4px;
  color: #00aced;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
  display: block;
  transition: .4s;
}
.btn:hover {
  background-color: #00aced;
  color: #fff;
}
.btn.btn--reverse {
  background-color: #00aced;
  color: #fff;
}
.btn.btn--reverse:hover {
  background-color: transparent;
  color: #00aced;
}
.btn.bnt--brown {
  color: #592b18;
  border-color: #592b18;
}
.btn.bnt--brown:hover {
  color: #fff;
  background-color: #592b18;
}
.btn.bnt--gold {
  color: #d9aa8a;
  border-color: #d9aa8a;
}
.btn.bnt--gold:hover {
  color: #fff;
  background-color: #d9aa8a;
}
.btn.bnt--white {
  color: #fff;
  border-color: #fff;
}
.btn.bnt--white:hover {
  color: #00aced;
  background-color: #fff;
}
.inner {
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
}

@media (max-width: 740px) {
  .btn {
    width: 100px;
    padding: 8px;
    font-size: 12px;
  }
}