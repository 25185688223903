.branch {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  margin-top: 10px;
  height: 570px;
}
.branch .inner {
  position: relative;
  margin: 0 auto;
}
.branch .inner .title {
  padding-top: 80px;
  width: 100%;
  position: relative;
}
.branch .inner .title .strong {
  font-weight: bold;
  color: #d9aa8a;
}
.branch .inner .title h2 {
  font-size: 32px;
  font-weight: 500;
  background-color: #f6f5ef;
  border-radius: 5px;
  text-align: center;
  padding: 20px 0;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, .15);
}

/* 브랜치 */
.branch .content {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;  
}
.branch .content .subject {
  width: 250px;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  perspective: 600px;
  z-index: 1;
}
.branch .content .subject .branch-tree {
  height: 270px;
  margin-top: -75px;
}
.branch .content .subject .branch-tree img {
  height: 100%;
}
.branch .content .subject .front,
.branch .content .subject .back {
  width: 250px;
  height: 250px;
  position: absolute;
  bottom: 0;
  backface-visibility: hidden;
  transition: 1s;
  display: flex;
  justify-content: center;
  align-items: center;
}
.branch .content .subject .front img {
  width: 175px;
  height: 175px;
}
.branch .content .subject .front {
  transform: rotateY(0deg);
}
.branch .content .subject:hover .front {
  transform: rotateY(180deg);
}
.branch .content .subject .back {
  transform: rotateY(-180deg);
}
.branch .content .subject:hover .back {
  transform: rotateY(0deg);
}

@media (max-width: 1000px) {
  .branch {
    height: 525px;
  }
  .branch .content {
    height: 350px;
  }
  .branch .inner .title {
    width: 90%;
    margin: 0 auto;
  }
  .branch .inner .title h2 {
    font-size: 26px;
  }
  .branch .content .subject {
    width: 200px;
  }
  .branch .content .subject .branch-tree {
    margin-top: -50px;
    position: relative;
  }
  .branch .content .subject .front,
  .branch .content .subject .back {
    width: 200px;
    height: 200px;
  }
  .branch .content .subject .front img {
    width: 155px;
    height: 155px;
  }
  .branch .content .subject .back img {
    width: 220px;
    height: 220px;
  }
}

@media (max-width: 740px) {
  .branch {
    height: 550px;
  }
  .branch .content {
    flex-wrap: wrap;
    height: auto;
  }
  .branch .inner .title {
    padding-top: 40px;
  }
  .branch .inner .title .first-text,
  .branch .inner .title .second-text {
    display: block;
  }
  .branch .inner .title .first-text {
    padding-bottom: 3px;
  }
  .branch .inner .title h2 {
    padding: 15px;
    font-size:18px;
  }
  .branch .content .subject {
    width: 45vW;
    height: 200px;
  }
  .branch .content .subject .branch-tree {
    height: 300px;
    margin-top: 0;
  }
  .branch .content .subject.science,
  .branch .content .subject.genius {
    margin-top: -20px;
  }
  .branch .content .subject.science .branch-tree,
  .branch .content .subject.genius .branch-tree {
    display: none;
  }
  .branch .content .subject .front,
  .branch .content .subject .back {
    width: 160px;
    height: 160px;
  }
  .branch .content .subject .front img {
    width: 140px;
    height: 140px;
  }
  .branch .content .subject .back img {
    width: 185px;
    height: 185px;
  }
}